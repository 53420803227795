<template>
    <ul class="toast-list">
        <li v-for="toastStateItem in toastListState" :key="toastStateItem.id">
            <toast-item :toastState="toastStateItem" @hide-toast="onRemoveToast" />
        </li>
    </ul>
</template>

<script>
import toastItem from './toast-item';
import headerStore from '../stores/header';

export default {
    name: 'toast-list',
    components: {
        toastItem,
    },

    data() {
        return {
            toastListState: headerStore.state.toastList,
        };
    },

    methods: {
        onRemoveToast(id) {
            this.$header.removeToast(id);
        },
    },
};
</script>

<style lang="scss" scoped>
.toast-list {
    list-style-type: none;
    padding-left: 0;
}
</style>
