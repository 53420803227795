/**
 * User Infos Service.
 * Tools to get user infos.
 */
export default {
    getGidFromEmail,
};

function getGidFromEmail(email) {
    return email ? email.split('@')[0] : null;
}
