const routes = {
    EXECUTIVE: {
        path: '/executive',
        name: 'executive-page',
        title: 'Cadre',
    },
    MANAGER: {
        path: '/manager',
        name: 'manager-page',
        title: 'Manager',
    },
    HR: {
        path: '/hr',
        name: 'hr-page',
        title: 'Ressources humaines',
    },
};

export default routes;
