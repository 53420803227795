export default {
    NETWORK: {
        TITLE: 'Aucune connexion',
        MESSAGE: 'Vérifiez que vous êtes bien connecté.',
    },
    HTTP: {
        _404: {
            TITLE: 'Ressource introuvable.',
            MESSAGE: 'La ressource demandée est introuvable !',
        },
        _500: {
            TITLE: 'Problème serveur',
            MESSAGE: 'Une erreur de serveur a été détectée et est en cours de résolution.',
        },
        _401: {
            TITLE: 'Utilisateur non reconnu',
            MESSAGE: 'Veuillez réessayer ou vérifiez vos droits d’accès à eDayWork.',
        },
        _403: {
            TITLE: 'Accès non autorisé',
            MESSAGE: "Il semblerait que vous n'ayez pas accès à cette page",
        },
    },
};
