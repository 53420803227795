import Vue from 'vue';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import axios from 'axios';
import App from '@/app';
import router from '@/router';
import store from '@/stores';
import vuetify from '@/plugins/vuetify';
import headerPlugin from '@/plugins/header.plugin';
import headerStore from '@/stores/header';
import apiErrorHelper from '@/services/api-error-helper';

Vue.use(headerPlugin, headerStore);

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
}).$mount('#app');

axios.interceptors.request.use(async (axiosRequestConfig) => {
    if (axiosRequestConfig.url.indexOf(process.env.VUE_APP_TIME_REPORT_API_BASE_URL) >= 0) {
        const idToken = await Vue.prototype.$auth.getIdToken();

        if (idToken) {
            axiosRequestConfig.headers.authorization = idToken;
        }
    }

    return axiosRequestConfig;
});
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        apiErrorHelper.handleErrorMessage(error, store, Vue.prototype.$header);
        Promise.reject(error);
    }
);
