<script>
import technicalMessagesConstants from '@/constants/technical-messages.constants';

export default {
    name: 'manage-redirection',

    async beforeMount() {
        await this.$store.dispatch('refreshUserInfos', { vm: this });
        const userAvailablePages = await this.$store.state.userAvailablePages;

        if (userAvailablePages.length === 0) {
            this.$store.dispatch('errors/addFatalError', {
                title: technicalMessagesConstants.HTTP._401.TITLE,
                message: technicalMessagesConstants.HTTP._401.MESSAGE,
            });
        } else {
            await this.$router.replace(userAvailablePages[0]);
        }
    },

    render() {
        return '';
    },
};
</script>
