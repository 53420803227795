const prototype = {
    toParamString(...params) {
        let parameterizedString = this.MESSAGE;

        params.forEach((param) => {
            parameterizedString = parameterizedString.replace(/{.*?}/, param);
        });

        return parameterizedString;
    },
};

const messages = {
    GET_HR_REFEREES_FAILED: Object.create(prototype),
    GET_MANAGER_COWORKERS_FAILED: Object.create(prototype),
    GET_COWORKERS_WORKED_DAYS_NUMBER_FAILED: Object.create(prototype),
    GET_TIME_REPORT_SUMMARY_FAILED: Object.create(prototype),
    GET_TIME_REPORT_FAILED: Object.create(prototype),
    USER_AUTHENTICATION_FAILED: Object.create(prototype),
    USER_NOT_FOUND: Object.create(prototype),
    VALIDATION_FAILED: Object.create(prototype),
    GET_PUBLIC_HOLIDAYS_FAILED: Object.create(prototype),
    VALIDATION_BY_MAIL_FAILED: Object.create(prototype),
    GET_FLAT_RATE_WORK_FAILED: Object.create(prototype),
    VALIDATION_SUCCESS: Object.create(prototype),
    VALIDATION_ALREADY_VALIDATED: Object.create(prototype),
    VALIDATION_VALIDATED: Object.create(prototype),
    UPDATE_SUCCESS: Object.create(prototype),
};

messages.GET_HR_REFEREES_FAILED.TITLE = 'Erreur';
messages.GET_HR_REFEREES_FAILED.MESSAGE =
    "Impossible de récupérer la liste des résumés d'activité des référés de l'utilisateur {userGid}.";

messages.GET_MANAGER_COWORKERS_FAILED.TITLE = 'Erreur';
messages.GET_MANAGER_COWORKERS_FAILED.MESSAGE =
    "Impossible de récupérer la liste des résumés d'activité des collaborateurs de l'utilisateur {userGid}.";

messages.GET_COWORKERS_WORKED_DAYS_NUMBER_FAILED.TITLE = 'Erreur';
messages.GET_COWORKERS_WORKED_DAYS_NUMBER_FAILED.MESSAGE =
    "Impossible de récupérer la liste des nombres de jours travaillés des collaborateurs de l'utilisateur {userGid}.";

messages.GET_TIME_REPORT_SUMMARY_FAILED.TITLE = 'Erreur';
messages.GET_TIME_REPORT_SUMMARY_FAILED.MESSAGE =
    "Impossible de récupérer la liste des résumés des saisies de temps de l'utilisateur {userGid}.";

messages.GET_TIME_REPORT_FAILED.TITLE = 'Erreur';
messages.GET_TIME_REPORT_FAILED.MESSAGE =
    "Les informations sur vos jours d'activité validés n'ont pu être récupérées.\nVeuillez réessayer ultérieurement.";

messages.USER_AUTHENTICATION_FAILED.TITLE = 'Erreur';
messages.USER_AUTHENTICATION_FAILED.MESSAGE = "Problème d'authentification";

messages.GET_FLAT_RATE_WORK_FAILED.TITLE = 'Erreur';
messages.GET_FLAT_RATE_WORK_FAILED.MESSAGE =
    "Impossible de récupérer le forfait jours travaillés de l'utilisateur {userGid}.";
messages.USER_NOT_FOUND.TITLE = 'Erreur';
messages.USER_NOT_FOUND.MESSAGE = "L'utilisateur avec le gid {userGid} n'existe pas.";

messages.VALIDATION_BY_MAIL_FAILED.TITLE = 'Erreur validation par mail';
messages.VALIDATION_BY_MAIL_FAILED.MESSAGE =
    "L'activité n'a pas été validée par mail.\nVeuillez renouveler l'action sur le site.";

messages.VALIDATION_FAILED.TITLE = 'Erreur validation';
messages.VALIDATION_FAILED.MESSAGE =
    "L'activité n'a pas été validée.\nVeuillez réessayer ultérieurement.";

messages.GET_PUBLIC_HOLIDAYS_FAILED.TITLE = 'Erreur';
messages.GET_PUBLIC_HOLIDAYS_FAILED.MESSAGE = 'Impossible de récupérer la liste des jours fériés.';

messages.UPDATE_SUCCESS.TITLE = 'Mise à jour';
messages.UPDATE_SUCCESS.MESSAGE = 'Votre activité {yearMonth} a été mise à jour.';

messages.VALIDATION_SUCCESS.TITLE = 'Validation';
messages.VALIDATION_SUCCESS.MESSAGE = 'Votre activité de {yearMonth} a été validée.';
messages.VALIDATION_ALREADY_VALIDATED.MESSAGE = 'Votre activité {yearMonth} a déjà été validée.';
messages.VALIDATION_VALIDATED.MESSAGE = 'Votre activité {yearMonth} a été validée.';

export default messages;
