<script>
export default {
    name: 'implicit-callback',

    async beforeMount() {
        await this.$auth.handleAuthentication();
        await this.$store.dispatch('refreshUserInfos', { vm: this });
        await this.$router.replace({
            path: this.$auth.getFromUri(),
        });
    },

    render() {
        return '';
    },
};
</script>
