<template>
    <div>
        <v-dialog
            class="my-dialog"
            transform-origin=" top left"
            persistent
            hide-overlay
            v-model="toast.value"
            width="390px"
        >
            <v-card rounded>
                <v-toolbar height="35px" dark :color="toast.color">
                    <v-btn dark text>
                        <v-icon size="30" v-if="isValidated">mdi-check-circle</v-icon>
                        <v-icon size="30" v-if="!isValidated && !isAlreadyValidated">
                            mdi-alert-circle
                        </v-icon>
                        <v-icon size="30" v-if="isAlreadyValidated">mdi-alert</v-icon>
                    </v-btn>

                    <div class="toast-title">{{ toast.title }}</div>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="hideToast">
                            <v-icon size="30">mdi-close-circle-outline</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <div class="toast-message">
                    <span>{{ toast.message }}</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import color from '../styles/colors.scss';

export default {
    name: 'toast-item',

    props: {
        toastState: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            toast: this.toastState,
        };
    },

    computed: {
        isValidated() {
            return this.toast.color !== color.error && this.toast.color !== color.warning;
        },
        isAlreadyValidated() {
            return this.toast.color === color.warning && this.toast.color !== color.error;
        },
    },
    methods: {
        hideToast() {
            this.toast.value = false;
            this.$emit('hide-toast', this.toast.id);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';

.my-dialog .vts-dialog__content {
    position: relative;
    border-radius: 20px;
    padding: 20px;
    font-family: sans-serif;
    top: 0;
    left: 0;
    background: $white;
    transition: 0.3s ease transform;
}

::v-deep .v-toolbar__content {
    padding: 0;

    .v-btn.v-btn--icon.v-size--default,
    .v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
        padding: 0;
        margin-right: 15px;
        width: 0;
    }

    .v-btn:not(.v-btn--round).v-size--default {
        padding: 0;
        min-width: 0;
        margin-left: 5px;
    }

    .v-toolbar__extension {
        padding: 0;
    }
}

.toast-title {
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.1em;
    font-family: sans-serif, Arial;
}

.toast-message {
    padding: 15px 15px 20px 15px;
    text-align: center;
    font-family: sans-serif, Arial;
    background: $very-light-gray;
}

.v-dialog__content {
    font-size: small;
}

@media (min-width: 900px) {
    ::v-deep .v-dialog {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
    }
}
</style>
