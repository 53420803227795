<template>
    <v-app-bar app color="white" elevation="0" height="70">
        <div class="header">
            <div class="edaywork-logo" to="/">
                <v-img
                    alt="Logo"
                    class="shrink mr-2"
                    contain
                    :src="logo"
                    transition="scale-transition"
                    width="90"
                />
            </div>

            <div v-if="$store.state.userInfos" class="profile">
                <div>
                    Bonjour
                    <span class="bold">{{ $store.state.userInfos.name }}</span>
                </div>

                <div v-if="$store.state.userRoles.isExec" class="annual-counter">
                    <div class="label">Compteur annuel des jours travaillés</div>
                    <div :class="{ 'red--text': isPeriodOverWorked }" class="bold">
                        {{ $store.state.userTotalWorkedDays }} /
                        {{
                            userFlatRateWork >= 0 ? `${userFlatRateWork}` : 'information manquante'
                        }}
                    </div>
                </div>
            </div>
            <toast-list />
        </div>

        <template v-slot:extension>
            <div class="extension">
                <v-divider class="divider"></v-divider>

                <v-tabs
                    v-if="!$store.getters['errors/hasError']"
                    centered
                    active-class="active-route"
                    hide-slider
                >
                    <v-tab
                        v-for="(route, index) in pages"
                        :key="index"
                        :to="{ path: route.path }"
                        :ripple="false"
                    >
                        {{ route.title }}
                    </v-tab>
                </v-tabs>

                <v-fab-transition
                    v-if="$store.state.isMobilePlatform && $store.state.canHideMonthlySummary"
                >
                    <v-btn
                        color="primary"
                        fab
                        dark
                        small
                        absolute
                        bottom
                        left
                        @click="toggleMothlySummaryMenu()"
                    >
                        <v-icon>mdi-calendar-multiple-check</v-icon>
                    </v-btn>
                </v-fab-transition>
            </div>
        </template>
    </v-app-bar>
</template>

<script>
import toastList from '@/components/toast-list';
import routesConstants from '../../constants/routes.constants';
import blueLogo from '../../assets/edaywork-logo-default.png';
import mochaLogo from '../../assets/edaywork-logo-mocha.png';
import purpleLogo from '../../assets/edaywork-logo-purple.png';

export default {
    name: 'default-header',

    components: {
        toastList,
    },

    data() {
        return {
            blueLogo,
            mochaLogo,
            purpleLogo,
        };
    },

    computed: {
        roleFromRoute() {
            return this.$route.path.split('/')[1];
        },
        logo() {
            switch (this.roleFromRoute) {
                case 'executive':
                    return this.blueLogo;
                case 'manager':
                    return this.mochaLogo;
                case 'hr':
                    return this.purpleLogo;
                default:
                    return this.blueLogo;
            }
        },

        pages() {
            const mainPages = {};

            if (this.$store.state.userRoles.isExec) {
                mainPages.EXECUTIVE = routesConstants.EXECUTIVE;
            }
            if (this.$store.state.userRoles.isManager) {
                mainPages.MANAGER = routesConstants.MANAGER;
            }
            if (this.$store.state.userRoles.isHR) {
                mainPages.HR = routesConstants.HR;
            }

            return mainPages;
        },

        isPeriodOverWorked() {
            return this.$store.state.userTotalWorkedDays >= 0 && this.userFlatRateWork >= 0
                ? this.$store.state.userTotalWorkedDays > this.userFlatRateWork
                : false;
        },

        userFlatRateWork() {
            return this.$store.state.userFlatRateWork;
        },
    },
    methods: {
        toggleMothlySummaryMenu() {
            this.$store.dispatch('toggleMonthlySummary');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/colors.scss';

.header {
    display: flex;
    align-items: center;
    width: 100%;

    .profile {
        position: absolute;
        text-align: center;
        top: 5px;
        max-width: 200px;
        right: 10px;

        .annual-counter {
            border: 2px solid;
            border-radius: 10px;

            .label {
                font-size: 0.875em;
            }
            .name {
                font-size: 1em;
            }
            .count {
                font-size: 1.125em;
                font-weight: bold;
            }
        }
    }
}

.divider {
    max-width: 60%;
    margin: 0 auto;
}

.active-route {
    background-color: var(--v-primary-base);
    margin: 8px;
    border-radius: 5px;
    color: white !important;
}

.v-tab {
    text-transform: none;
}

.theme--light.v-tabs .v-tab:focus::before,
.theme--light.v-tabs .v-tab:hover::before {
    opacity: 0;
}

.extension {
    width: 50%;
    margin: 0 auto;
}

@media (min-width: $sm) {
    .header {
        justify-content: center;

        .edaywork-logo {
            margin-left: 8px;
        }
    }
}

@media (max-width: $sm) {
    .extension {
        width: 100%;
        margin: 0;
    }
    .header {
        width: 100%;

        .profile {
            left: inherit;
            right: 10px;
        }
    }
}
</style>
