import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#6ED2B1',
                secondary: '#51806F',
                accent: '#538AAE',
                error: '#cc0000',
                info: '#53806F',
                success: '#6ED2B1',
                warning: '#E97949',
            },
        },
        options: { customProperties: true },
    },
});
