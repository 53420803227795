import axios from 'axios';
import utilsService from './utils.service';

/**
 * flat rate work API Service.
 * Communication interface for the FlatRateWork REST API.
 */
export default {
    getFlatRateWorkByGid,
};

const flatRateWorkBaseUrl = `${process.env.VUE_APP_TIME_REPORT_API_BASE_URL}/flat_rate_works`;

/**
 * Return the flat rate work filtered by user Gid.
 *
 * @param {String} userGid - The user Gid.
 * @param {String} startDate - The period reference start date.
 * @param {String} endDate - The period reference end date.
 * @returns {Promise<Object>}
 */
async function getFlatRateWorkByGid(userGid, startDate, endDate) {
    return axios
        .get(`${flatRateWorkBaseUrl}/${userGid}?start_period=${startDate}&end_period=${endDate}`)
        .then((result) => {
            const user = utilsService.fromSnakeCaseToCamelCase(result.data);
            return user;
        });
}
