// eslint-disable-next-line import/no-unresolved

export default {
    fromCamelCaseToSnakeCase,
    fromSnakeCaseToCamelCase,
    upperCaseFirstLetter,
    isFirstLetterVowel,
    isArraysEqual,
    isFirstConnectionDone,
    setFirstConnection,
    isItValidatedTimeReport,
};

/**
 * Test if two arrays are equal
 * @param {Array} a the first array
 * @param {Array} b The second array
 * @param {String} prop The property on which we want to test equality
 * @returns {Booelan}
 */
function isArraysEqual(a, b, prop = null) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const sortedArray1 = sortArray(a, prop);
    const sortedArray2 = sortArray(b, prop);

    for (let i = 0; i < sortedArray1.length; i += 1) {
        if (sortedArray1[i] !== sortedArray2[i]) return false;
    }
    return true;
}

/**
 * Map an array based on given property and use default Sort (alphabetically)
 * @param {Array} a the first array
 * @param {String} prop The property on which we want to test equality
 * @returns {Array}
 */
function sortArray(a, prop = null) {
    let array;
    if (prop) {
        array = a.map((item) => item[prop]);
    } else {
        array = a.slice();
    }
    array.sort();

    return array;
}

function processForValue(val, functionToExecute) {
    // eslint-disable-next-line no-nested-ternary
    return typeof val !== 'object'
        ? val
        : Array.isArray(val)
        ? val.map(functionToExecute)
        : functionToExecute(val);
}

/**
 * Transforms an object from camel case to snake case
 * @param {Object} obj The object given to transform
 * @returns {Object}
 */
function fromCamelCaseToSnakeCase(obj) {
    if (!obj) return undefined;
    if (typeof obj !== 'object') return obj;
    return Object.fromEntries(
        Object.entries(obj).map(([key, val]) => [
            key.replace(/([A-Z])/g, '_$1').toLowerCase(),
            processForValue(val, fromCamelCaseToSnakeCase),
        ])
    );
}

/**
 * Transforms an object from snake case to camel case
 * @param {Object} obj The object given to transform
 * @returns {Object}
 */
function fromSnakeCaseToCamelCase(obj) {
    if (!obj) return undefined;
    if (typeof obj !== 'object') return obj;
    return Object.fromEntries(
        Object.entries(obj).map(([key, val]) => [
            key.replace(/_(.)/g, (g) => g[1].toUpperCase()),
            processForValue(val, fromSnakeCaseToCamelCase),
        ])
    );
}

/**
 * Transforms the given string into a first capital letter
 * @param {String} string the string to transform
 * @returns {String} string with first capital letter
 */
function upperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Check in a string if the first letter is a vowel
 * @param {String} string
 */
function isFirstLetterVowel(string) {
    const arrayVowels = ['a', 'e', 'i', 'o', 'u'];
    return arrayVowels.some((x) => string.substr(0, 1) === x);
}
/**
 * check if this is the first connection to edayWork is done
 * @returns {Boolean}  a boolean locally saved that determines if this is the first connection to edayWork
 */
function isFirstConnectionDone() {
    return localStorage.getItem('is-first-connection-done');
}
/**
 * Set is-first-connection-done locally saved with value sent as input params
 * @param {Boolean} value
 */
function setFirstConnection(value) {
    localStorage.setItem('is-first-connection-done', value);
}

/**
 * Check if the timerReport is validated
 * @param {Object} timeReport
 * @returns Boolean
 */
function isItValidatedTimeReport(timeReport) {
    return !!(timeReport && timeReport.lastUserModificationDate);
}
