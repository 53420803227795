import axios from 'axios';
import utilsService from './utils.service';

/**
 * User API Service.
 * Communication interface for the User REST API.
 */
export default {
    getUserByGid,
};

const userBaseUrl = `${process.env.VUE_APP_TIME_REPORT_API_BASE_URL}/users`;

/**
 * Return the user filtered by their Gid.
 *
 * @param {String} userGid - The user Gid.
 * @returns {Promise<Object>}
 */
async function getUserByGid(userGid) {
    return axios.get(`${userBaseUrl}/${userGid}`).then((result) => {
        const user = utilsService.fromSnakeCaseToCamelCase(result.data);
        return user;
    });
}
