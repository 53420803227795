import axios from 'axios';

export default {
    getPublicHolidays,
};

const publicHolidayBaseUrl = `${process.env.VUE_APP_TIME_REPORT_API_BASE_URL}/public_holidays`;

/**
 * Returns the public holidays list of the given year.
 *
 * @param {number} year   - The year to get public holidays.
 * @param {string} [zone] - Specific zone to get public holidays. Optional, default is 'metropole'.
 * @returns {Promise<Object>}
 */
function getPublicHolidays(year, zone) {
    return axios.get(`${publicHolidayBaseUrl}/${zone}/${year}`).then((result) => result.data);
}
