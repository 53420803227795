import axios from 'axios';
import utilsService from './utils.service';

/**
 * TimeReport API Service.
 * Communication interface for the TimeReport REST API.
 */
export default {
    getTimeReportByUserGidAndYearMonth,
    getTotalWorkedDays,
    postTimePorts,
    createTimeReport,
    updateTimeReport,
};

const timeReportBaseUrl = `${process.env.VUE_APP_TIME_REPORT_API_BASE_URL}/time_reports`;
const yearMonthRegexMatcher = /^\d{4}-\d{2}$/g;

/**
 * Post a new time report given as an input parameter
 *
 * @param {Object} timeReport - The time report to post.
 * @returns {Promise<Object>}
 */
async function postTimePorts(timeReport) {
    return axios
        .post(timeReportBaseUrl, utilsService.fromCamelCaseToSnakeCase(timeReport))
        .then((result) => result.data);
}

/**
 * Return the time report filtered by user gid and year/month.
 *
 * @param {String} userGid - The user gid used to get the time report.
 * @param {String} yearMonth - The year month used to get the time report.
 * @returns {Promise<Object>}
 */
async function getTimeReportByUserGidAndYearMonth(userGid, yearMonth) {
    const result = await axios.get(`${timeReportBaseUrl}/${userGid}/${yearMonth}`);
    const formattedResult = utilsService.fromSnakeCaseToCamelCase(result.data);
    return utilsService.isItValidatedTimeReport(formattedResult) ? formattedResult : null;
}

/**
 * Returns total worked days of user for a period.
 *
 * @param {String} userGid - The user gid used.
 * @param {String} yearMonthStart - Start date of the period.
 * @param {String} yearMonthEnd - End date of the period.
 * @returns {Promise<Array<Object>>}
 */
async function getTotalWorkedDays(userGid, yearMonthStart, yearMonthEnd) {
    return axios
        .get(
            `${timeReportBaseUrl}/total_worked_days_count/${userGid}?year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}`
        )
        .then((result) => result.data);
}

/**
 * Save a new time report.
 *
 * @param {String} userGid - The current user GID
 * @param {string} yearMonth - The year-month of the time report. Must have the format yyyy-MM.
 * @param {Array<Object>} workedDays - A worked days array.
 * @returns {Promise<Object>}
 */
async function createTimeReport(userGid, yearMonth, workedDays, totalWorkedDays) {
    if (!userGid) {
        return Promise.reject(new Error('Invalid userGid'));
    }

    if (!yearMonth) {
        return Promise.reject(new Error('Invalid yearMonth'));
    } else if (!yearMonth.match(yearMonthRegexMatcher)) {
        return Promise.reject(new Error('Invalid format for yearMonth'));
    }

    if (!Array.isArray(workedDays)) {
        return Promise.reject(new Error('Invalid parameter'));
    }

    const timeReport = {
        userGid,
        yearMonth,
        workedDays,
        totalWorkedDays,
    };

    return axios
        .post(timeReportBaseUrl, utilsService.fromCamelCaseToSnakeCase(timeReport))
        .then((result) => utilsService.fromSnakeCaseToCamelCase(result.data));
}

/**
 * Update the time report in case this one exist.
 *
 * @param {Object} timeReport - The time report to update.
 * @returns {Promise<Object>}
 */
async function updateTimeReport(timeReport) {
    if (!timeReport) {
        return Promise.reject(new Error('Invalid timeReport'));
    }

    return axios
        .put(`${timeReportBaseUrl}`, utilsService.fromCamelCaseToSnakeCase(timeReport))
        .then((result) => utilsService.fromSnakeCaseToCamelCase(result.data));
}
