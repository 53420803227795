<template>
    <div class="text-center">
        <v-dialog v-model="isDialogDisplayed" width="1000">
            <template v-slot:activator="{ on }">
                <span dark class="pointer" v-on="on">Mention d'information RGPD</span>
            </template>

            <v-card>
                <v-card-title dark class="primary white--text">
                    eDayWork – Mention d’information RGPD
                </v-card-title>

                <v-card-text class="rgpd-mention">
                    <v-spacer />
                    <p class="fist-paragraph">
                        Les données à caractère personnel présentes dans l’application eDayWork font
                        l’objet d’un traitement informatique ayant pour finalités d’assurer le
                        décompte des temps de travail des collaborateurs au statut « cadre »
                        conformément aux dispositions légales et réglementaires applicables aux
                        conventions de forfait en jours. Ce traitement permet également de repérer
                        et de réagir face aux éventuelles situations de « sur-travail » afin de
                        prévenir les risques psychosociaux et de permettre l’élaboration de
                        statistiques d’utilisation de la plateforme.
                    </p>
                    <p>
                        Ce traitement est réalisé par votre employeur, ENGIE ENERGIE SERVICES, en sa
                        qualité de responsable de traitement, sur la base de dispositions
                        applicables en matière de droit du travail qui lui incombent, en particulier
                        celles consistant à se doter des modalités adaptées pour assurer le décompte
                        des journées et demi-journées travaillées par les salariés au statut cadre.
                    </p>
                    <p>
                        Pour accomplir cette finalité, votre employeur traite des données vous
                        concernant issues de votre dossier administratif (collecte indirecte) et des
                        données que vous renseignez sur une base déclarative dans la plateforme
                        eDayWork (collecte directe).
                    </p>
                    <p class="identification-data">
                        Plus précisément, les données issues de votre dossier administratif traitées
                        pour cette finalité sont :
                    </p>
                    <ul class="identification-data-list">
                        <li>vos données d’identification (nom, prénom) ;</li>
                        <li>
                            les données relatives à votre vie professionnelle (votre fonction,
                            courriel professionnel, jours d’ancienneté, classification, liens
                            hiérarchiques, type de contrat de travail) ;
                        </li>
                        <li>
                            les données que vous saisissez (de manière déclarative) dans l’outil
                            eDayWork sont : vos journées et/ou demi-journées de travail.
                        </li>
                    </ul>
                    <v-spacer />
                    <p>
                        Ces données sont historisées et conservées dans l’outil pendant la période
                        de période de référence en cours et les 3 années précédentes, afin de se
                        conformer aux obligations légales en vigueur (art. L. 3245-1 Code du
                        travail), durée à l'issue de laquelle les données sont archivées en base
                        intermédiaire pendant 2 années supplémentaires, soit jusqu’à l’acquisition
                        de la prescription civile. En cas de contentieux, ces données sont
                        conservées pendant toute la durée de la procédure et jusqu'à l'expiration
                        des voies de recours ordinaires et extraordinaires.
                    </p>
                    <p>
                        Ces données sont traitées par les services de la DRH d’ENGIE Energie
                        Services habilités à les connaître dans le cadre de leurs missions. Ces
                        données sont également accessibles à votre ligne managériale qui est en
                        charge de veiller à ce que l’amplitude et la charge de travail vous
                        incombant soient raisonnables.
                    </p>
                    <p>
                        Ces données peuvent le cas échéant, être mises à disposition des autorités
                        judiciaires et administratives, ainsi qu’aux organismes sociaux, qui en
                        feraient la demande dans le cadre de leurs attributions.
                    </p>
                    <p>
                        Certaines données sont également traitées par différents prestataires
                        techniques auxquels votre employeur a recours pour sous-traiter
                        l’hébergement de la présente application. La relation avec ces prestataires
                        a été encadrée par votre employeur conformément à l’article 28 du RGPD.
                    </p>
                    <p>
                        Conformément à la loi Informatique et libertés du 6 janvier 1978 et au
                        Règlement européen 2016/679 du 27 avril 2016, vous disposez d’un droit
                        d’accès, de rectification, de limitation des données qui vous concernent.
                        Vous disposez enfin du droit de définir des directives générales et
                        particulières définissant la manière dont vous entendez que soient exercés,
                        après votre décès, ces droits.
                    </p>
                    <p>
                        Pour exercer vos droits, il suffit d’en faire la demande à l’adresse
                        courriel : dpm.engie-solutions@engie.com ou à ENGIE Energie Services – Data
                        Privacy Manager - 1 place Samuel de Champlain – Faubourg de l’Arche - 92930
                        Paris La Défense Cedex - case courrier 12-28.
                    </p>
                    <p>
                        Vous êtes également informé(e) que vous pouvez faire valoir vos droits
                        auprès de la CNIL au sujet du traitement de vos données par ENGIE Energie
                        Services.
                    </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="hideRgpdMention()">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import utilsService from '../services/utils.service';

export default {
    name: 'rgpd-mention',
    data() {
        return {
            isDialogDisplayed: false,
        };
    },
    mounted() {
        this.isDialogDisplayed = !utilsService.isFirstConnectionDone();
    },
    methods: {
        hideRgpdMention() {
            this.isDialogDisplayed = false;

            if (!utilsService.isFirstConnectionDone()) {
                utilsService.setFirstConnection(true);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.rgpd-mention {
    text-align: justify;
    text-justify: inter-word;
    .fist-paragraph {
        margin-top: 10px;
    }
    .identification-data {
        margin-bottom: 0;
    }
    .identification-data-list {
        margin-bottom: 10px;
    }
}
.pointer {
    cursor: pointer;
}
</style>
