import Vue from 'vue';
import VueRouter from 'vue-router';
import Auth from '@okta/okta-vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import routesConstants from '@/constants/routes.constants';
import ImplicitCallback from '@/components/routing/implicit-callback';
import ManageRedicrection from '@/components/routing/manage-redirection';
import technicalMessagesConstants from '@/constants/technical-messages.constants';
import store from '@/stores';

const routes = [
    {
        path: '/implicit/callback',
        component: ImplicitCallback,
    },
    {
        path: routesConstants.EXECUTIVE.path,
        name: routesConstants.EXECUTIVE.name,
        component: () => import('../pages/executive-page'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesConstants.MANAGER.path,
        name: routesConstants.MANAGER.name,
        component: () => import('../pages/manager-page'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesConstants.HR.path,
        name: routesConstants.HR.name,
        component: () => import('../pages/hr-page'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/',
        component: ManageRedicrection,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '**',
        redirect: { path: '/' },
    },
];

const oktaConfig = {
    issuer: process.env.VUE_APP_OKTA_ISSUER,
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/implicit/callback`,
    pkce: false, // Need to be false to disable the default PKCE flow and use implicit OIDC flow
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

Vue.use(VueRouter);
Vue.use(Auth, oktaConfig);

router.beforeEach(customAuthRedirectGuard());

function customAuthRedirectGuard() {
    return async (to, from, next) => {
        if (
            to.matched.some((record) => record.meta.requiresAuth) &&
            !(await Vue.prototype.$auth.isAuthenticated())
        ) {
            await Vue.prototype.$auth.login(to.fullPath);
        } else if (await Vue.prototype.$auth.isAuthenticated()) {
            await store.dispatch('refreshUserInfos', { vm: Vue.prototype });
            if (
                (to.name === routesConstants.EXECUTIVE.name && !store.state.userRoles.isExec) ||
                (to.name === routesConstants.MANAGER.name && !store.state.userRoles.isManager) ||
                (to.name === routesConstants.HR.name && !store.state.userRoles.isHR)
            ) {
                store.dispatch('errors/addFatalError', {
                    title: technicalMessagesConstants.HTTP._403.TITLE,
                    message: technicalMessagesConstants.HTTP._403.MESSAGE,
                });
                next();
            } else {
                next();
            }
        } else {
            next();
        }
    };
}

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', process.env.VUE_APP_URL, /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE,
});

export default router;
