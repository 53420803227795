<template>
    <div class="error-page">
        <h1 class="error-title">Oups ! ({{ fatalErrors[0].title }})</h1>
        <div class="error-message">{{ fatalErrors[0].message }}</div>
    </div>
</template>

<script>
export default {
    name: 'error-management',

    computed: {
        fatalErrors() {
            return this.$store.state.errors.fatalErrors;
        },
    },

    methods: {
        removeFatalError(errorId) {
            this.$store.dispatch('errors/removeFatalError', errorId);
        },
    },
};
</script>

<style scoped lang="scss">
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .error-title {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .error-message {
        font-size: 1.1em;
    }
}
</style>
