import technicalMessagesConstants from '@/constants/technical-messages.constants';

/**
 * Check if the resulting error of an REST api call matches one of the given status codes
 * @param {import('axios').AxiosError|*} error The error to check
 * @param {number|Array<number>} codeOrCodes Code or Array of codes to check for (as numbers)
 * @returns {boolean} `true` if the error matches the status code (or one of the codes),
 * `false` otherwise. If the error has no status code, always returns `false`.
 */
function isErrorStatusCode(error, codeOrCodes) {
    if (!error || !error.response) return false;
    const codes = [].concat(codeOrCodes);
    const statusCode = error.response && error.response.status;
    return statusCode === undefined || statusCode === null ? false : codes.includes(statusCode);
}

/**
 * Returns `true` if the error is a network error (ie no connection or timeout,
 * including timed out request that might have been received and treated by the server)
 * @param {import('axios').AxiosError|*} error The error to check
 * @returns {boolean}
 */
function isNetworkError(error) {
    return error instanceof Error && error.message === 'Network Error';
}

/**
 * Log, display as Toast, and return a message for a given error thrown when calling an API.
 * @param {Error|import('axios').AxiosError} error Error throw when calling an API.
 */
function handleErrorMessage(error, store) {
    let title;
    let message;

    // KO PAGE MANAGEMENT
    if (isNetworkError(error)) {
        title = technicalMessagesConstants.NETWORK.TITLE;
        message = technicalMessagesConstants.NETWORK.MESSAGE;
    }
    if (isErrorStatusCode(error, [500, 503, 504])) {
        title = technicalMessagesConstants.HTTP._500.TITLE;
        message = technicalMessagesConstants.HTTP._500.MESSAGE;
    }
    if (isErrorStatusCode(error, 401)) {
        title = technicalMessagesConstants.HTTP._401.TITLE;
        message = technicalMessagesConstants.HTTP._401.MESSAGE;
    }
    if (isErrorStatusCode(error, 403)) {
        title = technicalMessagesConstants.HTTP._403.TITLE;
        message = technicalMessagesConstants.HTTP._403.MESSAGE;
    }

    // TOAST MANAGEMENT
    if (isErrorStatusCode(error, 404)) {
        title = technicalMessagesConstants.HTTP._404.TITLE;
        message = technicalMessagesConstants.HTTP._404.MESSAGE;
    }

    if (title && message) {
        return store.dispatch('errors/addFatalError', { title, message });
    } else {
        return error;
    }
}

export default {
    isErrorStatusCode,
    isNetworkError,

    handleErrorMessage,
};
