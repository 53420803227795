import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';

import userInfosService from '@/services/user-infos.service';
import userApiService from '@/services/user-api.service';
import flatRateWorkApiService from '@/services/flat-rate-work-api.service';
import workedDaysService from '@/services/worked-days.service';
import timeReportApiService from '@/services/time-report-api.service';

import mutationTypes from '@/stores/mutation-types';
import errors from '@/stores/errors';

import dateFormatConstants from '@/constants/date-format.constants';
import geoZoneConstants from '@/constants/geographical-zone.constants';
import routesConstants from '@/constants/routes.constants';
import messagesConstants from '@/constants/messages.constants';

Vue.use(Vuex);

const getters = {};
const actions = {
    async refreshUserInfos({ dispatch, commit, state }, { vm }) {
        if (!state.userGid) {
            const userOktaInfos = await vm.$auth.getUser();

            if (userOktaInfos && userOktaInfos.preferred_username) {
                const userGid = userInfosService.getGidFromEmail(userOktaInfos.preferred_username);
                commit(mutationTypes.SET_USER_INFOS, userOktaInfos);
                commit(mutationTypes.SET_USER_GID, userGid);

                try {
                    const todayDate = moment().format(dateFormatConstants.DATE_FORMAT);
                    const periodLimits = workedDaysService.getRangePeriod(todayDate);
                    const user = await userApiService.getUserByGid(userGid);
                    commit(mutationTypes.SET_USER_ZONE, user.zone);
                    dispatch('refreshUserTotalWorkedDays', {
                        userGid,
                        startPeriod: periodLimits.startDate,
                        endPeriod: periodLimits.endDate,
                    });
                    dispatch('refreshUserFlatRateWork', {
                        userGid,
                        startPeriod: periodLimits.startDate,
                        endPeriod: periodLimits.endDate,
                        vm,
                    });
                    dispatch('refreshUserRoles', user);
                    dispatch('refreshUserAvailablePages', user);
                } catch (error) {
                    vm.$header.addToast(
                        'error',
                        messagesConstants.USER_AUTHENTICATION_FAILED.TITLE,
                        `${messagesConstants.USER_AUTHENTICATION_FAILED.MESSAGE} ${error}`
                    );
                }
                return userGid;
            }
        }
        return state.userGid;
    },

    async refreshUserTotalWorkedDays({ commit }, { userGid, startPeriod, endPeriod }) {
        const yearMonthStart = moment(startPeriod).format(dateFormatConstants.YEAR_MONTH_FORMAT);
        const yearMonthEnd = moment(endPeriod).format(dateFormatConstants.YEAR_MONTH_FORMAT);
        const totalWorkedDays = await timeReportApiService.getTotalWorkedDays(
            userGid,
            yearMonthStart,
            yearMonthEnd
        );
        commit(mutationTypes.SET_USER_TOTAL_WORKED_DAYS, totalWorkedDays);
    },

    async refreshUserFlatRateWork({ commit, state }, { userGid, startPeriod, endPeriod, vm }) {
        try {
            if (userGid) {
                const userFlatRateWork = await flatRateWorkApiService.getFlatRateWorkByGid(
                    userGid,
                    startPeriod,
                    endPeriod
                );
                commit(mutationTypes.SET_USER_FLAT_RATE_WORK, userFlatRateWork);
            }

            return state.userFlatRateWork;
        } catch (error) {
            vm.$header.addToast(
                'error',
                messagesConstants.GET_FLAT_RATE_WORK_FAILED.TITLE,
                messagesConstants.GET_FLAT_RATE_WORK_FAILED.toParamString(userGid)
            );
            return undefined;
        }
    },

    onScreenResizeAction({ dispatch, commit }, isMobilePlatform) {
        commit(mutationTypes.SET_IS_MOBILE_PLATFORM, isMobilePlatform);
        if (isMobilePlatform) {
            dispatch('hideMonthlySummary');
        } else {
            dispatch('showMonthlySummary');
        }
    },
    showMonthlySummary({ commit, state }) {
        commit(mutationTypes.SET_DISPLAY_MONTHLY_SUMMARY, true);
        return state.isMonthlySummaryDisplayed;
    },
    hideMonthlySummary({ commit, state }) {
        commit(mutationTypes.SET_DISPLAY_MONTHLY_SUMMARY, false);
        return state.isMonthlySummaryDisplayed;
    },
    toggleMonthlySummary({ commit, state }) {
        commit(mutationTypes.SET_DISPLAY_MONTHLY_SUMMARY, !state.isMonthlySummaryDisplayed);
        return state.isMonthlySummaryDisplayed;
    },

    monthlySummaryCanBeHided({ commit, state }) {
        commit(mutationTypes.SET_CAN_HIDE_MONTHLY_SUMMARY, true);
        return state.canHideMonthlySummary;
    },
    monthlySummaryCannotBeHided({ commit, state }) {
        commit(mutationTypes.SET_CAN_HIDE_MONTHLY_SUMMARY, false);
        return state.canHideMonthlySummary;
    },

    async refreshUserRoles({ commit, state }, userInfos) {
        if (userInfos) {
            const userRoles = {
                isExec: userInfos.exec,
                isHR: userInfos.hr,
                isManager: userInfos.manager,
            };
            commit(mutationTypes.SET_USER_ROLES, userRoles);
        }
        return state.userRoles;
    },
    async refreshUserAvailablePages({ commit, state }, userInfos) {
        if (userInfos) {
            const userAvailablePages = [];
            if (userInfos.exec) {
                userAvailablePages.push(routesConstants.EXECUTIVE);
            }
            if (userInfos.manager) {
                userAvailablePages.push(routesConstants.MANAGER);
            }
            if (userInfos.hr) {
                userAvailablePages.push(routesConstants.HR);
            }
            commit(mutationTypes.SET_USER_AVAILABLE_PAGES, userAvailablePages);
        }
        return state.userAvailablePages;
    },
};

const mutations = {
    [mutationTypes.SET_USER_GID]: (state, gid) => {
        state.userGid = gid;
    },
    [mutationTypes.SET_USER_TOTAL_WORKED_DAYS]: (state, totalWorkedDays) => {
        state.userTotalWorkedDays = totalWorkedDays;
    },
    [mutationTypes.SET_USER_INFOS]: (state, userInfos) => {
        state.userInfos = {
            name: userInfos.name,
            email: userInfos.email,
            gidEmail: userInfos.preferred_username,
        };
    },
    [mutationTypes.SET_USER_FLAT_RATE_WORK]: (state, userFlatRateWork) => {
        state.userFlatRateWork = userFlatRateWork;
    },
    [mutationTypes.SET_DISPLAY_MONTHLY_SUMMARY]: (state, isMonthlySummaryDisplayed) => {
        state.isMonthlySummaryDisplayed = isMonthlySummaryDisplayed;
    },
    [mutationTypes.SET_USER_ROLES]: (state, userRoles) => {
        state.userRoles = userRoles;
    },
    [mutationTypes.SET_USER_AVAILABLE_PAGES]: (state, userAvailablePages) => {
        state.userAvailablePages = userAvailablePages;
    },
    [mutationTypes.SET_USER_ZONE]: (state, zone) => {
        state.userZone = zone;
    },
    [mutationTypes.SET_IS_MOBILE_PLATFORM]: (state, isMobilePlatform) => {
        state.isMobilePlatform = isMobilePlatform;
    },
    [mutationTypes.SET_CAN_HIDE_MONTHLY_SUMMARY]: (state, canHideMonthlySummary) => {
        state.canHideMonthlySummary = canHideMonthlySummary;
    },
};

const state = {
    userGid: undefined,
    userTotalWorkedDays: '',
    userInfos: {
        name: '',
        email: '',
        gidEmail: '',
    },
    userFlatRateWork: undefined,
    userRoles: {
        isExec: undefined,
        isManager: undefined,
        isHR: undefined,
    },
    userAvailablePages: [],
    userZone: geoZoneConstants.METROPOLE,

    isMonthlySummaryDisplayed: true,
    isMobilePlatform: false,
    canHideMonthlySummary: false,
};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        errors,
    },
});
