import moment from 'moment';
import dateFormatConstants from '@/constants/date-format.constants';
import geoZoneConstants from '@/constants/geographical-zone.constants';
import timeReportConstants from '@/constants/time-report.constants';
import publicHolidaysApi from './public-holidays-api.service';

export default {
    getPeriodMonthWorkingDays,
    getPeriodPublicHolidays,
    getMonthWorkingDays,
    getMonthWorkingDaysHavingPublicHolidays,
    isAWorkingDay,
    getDatesBetweenTwoDates,
    getRangePeriod,
    findWorkedDaysCountByYearMonth,
    getPublicHolidays,
};

const actualPublicHolidays = {
    [geoZoneConstants.METROPOLE]: {
        year: '0000',
        value: null,
    },
    [geoZoneConstants.ALSACE_MOSELLE]: {
        year: '0000',
        value: null,
    },
};
const lastPublicHolidays = {
    [geoZoneConstants.METROPOLE]: {
        year: '0000',
        value: null,
    },
    [geoZoneConstants.ALSACE_MOSELLE]: {
        year: '0000',
        value: null,
    },
};
const defaultDateFormat = dateFormatConstants.DATE_FORMAT;

/**
 * Returns the month working days of the given date.
 * @param periodDates
 * @param zone
 * @returns {number || null}
 */
async function getPeriodMonthWorkingDays(periodDates, zone) {
    const startDate = periodDates[0];
    const endDate = periodDates[periodDates.length - 1];

    const periodPublicHolidays = getPeriodPublicHolidays(startDate, endDate, zone);
    const periodMonthWorkingDays = [];
    for (let i = 0; i < periodDates.length; i += 1) {
        periodMonthWorkingDays.push(
            getMonthWorkingDaysHavingPublicHolidays(periodDates[i], periodPublicHolidays)
        );
    }
    return periodMonthWorkingDays;
}

async function getPeriodPublicHolidays(periodStartDate, periodEndDate, zone) {
    const startYearPublicHolidays = await getPublicHolidays(periodStartDate, zone);
    const endYearPublicHolidays = await getPublicHolidays(periodEndDate, zone);
    return { ...startYearPublicHolidays, ...endYearPublicHolidays };
}

/**
 * Returns the month working days of the given date.
 * @param {Date|string} date - Used to retrieve the working days of this date's month.
 * @param {string} zone - the user zone
 * @returns {number || null}
 */
async function getMonthWorkingDays(date, zone) {
    if (!date) {
        return null;
    }

    const today = moment(date).local('fr').format(defaultDateFormat);
    const yearPublicHolidays = await getPublicHolidays(today, zone);
    return getMonthWorkingDaysHavingPublicHolidays(date, yearPublicHolidays);
}

/**
 * Returns the month working days of the given date having yearPublicHolidays.
 * @param {Date|string} date          - Used to retrieve the working days of this date's month.
 * @param {Object} yearPublicHolidays - the year public holidays
 * @param isStartContractDate
 * @param isEndContractDate
 * @returns {number || null}
 */
function getMonthWorkingDaysHavingPublicHolidays(
    date,
    yearPublicHolidays,
    isStartContractDate,
    isEndContractDate
) {
    if (!date) {
        return null;
    }

    const startMonthDate = isStartContractDate
        ? moment(date).local('fr').format(defaultDateFormat)
        : moment(date).local('fr').startOf('month').format(defaultDateFormat);
    const endMonthDate = isEndContractDate
        ? moment(date).local('fr').format(defaultDateFormat)
        : moment(date).local('fr').endOf('month').format(defaultDateFormat);

    const monthDates = getDatesBetweenTwoDates(
        startMonthDate,
        endMonthDate,
        'day',
        defaultDateFormat
    );
    return monthDates.filter((monthDate) => isAWorkingDay(monthDate, yearPublicHolidays));
}

/**
 * Returns true if the given date is a working day, else returns false.
 * @param {Date|string} date          - The date to test.
 * @param {Object} yearPublicHolidays - The result of 'jours-feries' API (format : {"yyyy-MM-dd": String}).
 * @returns {boolean}
 */
function isAWorkingDay(date, yearPublicHolidays) {
    if (!date || !yearPublicHolidays) {
        return false;
    }
    const today = moment(date);
    const saturdayDay = 6;
    const sundayDay = 7;
    return (
        today.isoWeekday() !== saturdayDay &&
        today.isoWeekday() !== sundayDay &&
        !yearPublicHolidays[today.format(defaultDateFormat)]
    );
}

/**
 * Returns publicHolidays base on the date given
 * @param {Date|string} date - The date
 * @param zone
 * @returns {Object}
 */
async function getPublicHolidays(date, zone) {
    const actualYear = moment(date, dateFormatConstants.YEAR_MONTH_FORMAT).format('YYYY');

    if (actualPublicHolidays[zone].year === actualYear && actualPublicHolidays[zone].value) {
        return actualPublicHolidays[zone].value;
    }
    if (lastPublicHolidays[zone].year === actualYear && lastPublicHolidays[zone].value) {
        return lastPublicHolidays[zone].value;
    }

    const value = await publicHolidaysApi.getPublicHolidays(actualYear, zone);
    if (actualYear < actualPublicHolidays[zone].year) {
        lastPublicHolidays[zone].year = actualYear;
        lastPublicHolidays[zone].value = value;
    } else {
        actualPublicHolidays[zone].year = actualYear;
        actualPublicHolidays[zone].value = value;
    }
    return value;
}

/**
 * Returns the dates between 2 dates
 * @param {Date|string} startDate - The start date
 * @param {Date|string} endDate - The end date
 * @param interval
 * @param format
 * @returns {Array}
 */
function getDatesBetweenTwoDates(startDate, endDate, interval, format) {
    const today = moment(startDate);
    const dates = [];

    while (today.isSameOrBefore(endDate, interval)) {
        dates.push(today.format(format));
        today.add(1, interval);
    }
    return dates;
}

/**
 * Return an object containing start and end date of period according to given date.
 *
 * @param date - Given date used to calculate a period's limits.
 * @returns {Object}
 */
function getRangePeriod(date) {
    let year;
    let lastYear;

    if (moment(date).format('MM') >= 6 && moment(date).format('MM') <= 12) {
        lastYear = moment(date).format('YYYY');
        year = moment(date).add(1, 'years').format('YYYY');
    } else {
        lastYear = moment(date).subtract(1, 'years').format('YYYY');
        year = moment(date).format('YYYY');
    }
    return {
        startDate: `${lastYear}-06`,
        endDate: `${year}-05`,
    };
}

/**
 * Return the number of worked days for a given time report as string.
 *
 * @param periodReportSummary - List of time reports.
 * @param yearMonth - yearMonth of the time report we are looking for.
 * @returns {String}
 */
function findWorkedDaysCountByYearMonth(periodReportSummary, yearMonth) {
    if (!periodReportSummary) return undefined;
    const periodReportSummaryFound = periodReportSummary.find((element) => {
        return element.yearMonth === yearMonth;
    });

    return periodReportSummaryFound
        ? periodReportSummaryFound.numberOfWorkedDays
        : timeReportConstants.WORKED_DAYS_NUMBER.NOT_VALIDATED;
}
