<template>
    <default-layout>
        <router-view @change-theme="onChangeTheme" />
    </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default-layout';

export default {
    name: 'app',

    components: {
        DefaultLayout,
    },

    methods: {
        onChangeTheme(theme) {
            Object.keys(theme).forEach((i) => {
                this.$vuetify.theme.themes.light[i] = theme[i];
            });
            this.$vuetify.theme.themes.name = this.name;
        },
    },
};
</script>

<style lang="scss">
.html {
    font-size: 14px;
}
.bold {
    font-weight: bold;
}
</style>
