import mutationTypes from '@/stores/mutation-types';

const actions = {
    clearErrors({ commit }) {
        commit(mutationTypes.SET_FATAL_ERRORS, []);
    },
    addFatalError({ state }, { title, message }) {
        const error = {
            id: state.fatalErrors.length,
            title,
            message,
        };
        state.fatalErrors.push(error);
    },
    removeFatalError({ state }, errorId) {
        state.fatalErrors.splice(errorId, 1);
    },
};

const mutations = {
    [mutationTypes.SET_FATAL_ERRORS]: (state, fatalErrors) => {
        state.fatalErrors = fatalErrors;
    },
};

const getters = {
    hasError: (state) => state.fatalErrors.length > 0,
};

const state = {
    fatalErrors: [],
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
