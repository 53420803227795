<template>
    <v-app>
        <default-header />

        <v-main>
            <v-container fluid>
                <error-management v-if="$store.getters['errors/hasError']" />
                <slot v-else />
            </v-container>
        </v-main>

        <default-footer />
    </v-app>
</template>

<script>
import DefaultHeader from '@/components/layouts/default-header';
import DefaultFooter from '@/components/layouts/default-footer';
import ErrorManagement from '@/components/error-management';

export default {
    name: 'default-layout',

    components: {
        DefaultHeader,
        DefaultFooter,
        ErrorManagement,
    },
};
</script>
