export default {
    SET_USER_GID: 'setGid',
    SET_USER_INFOS: 'setUserInfos',
    SET_USER_TOTAL_WORKED_DAYS: 'setUserTotalWorkedDays',
    SET_USER_FLAT_RATE_WORK: 'setUserFlatRateWork',
    SET_USER_ROLES: 'setUserRoles',
    SET_USER_AVAILABLE_PAGES: 'setUserAvailablePages',
    SET_USER_ZONE: 'setUserZone',

    SET_DISPLAY_MONTHLY_SUMMARY: 'setDisplayMonthlySummary',
    SET_IS_MOBILE_PLATFORM: 'setIsMobilePlatform',
    SET_CAN_HIDE_MONTHLY_SUMMARY: 'setCanDisplayMonthlySummary',

    SET_FATAL_ERRORS: 'setFatalErrors',
};
