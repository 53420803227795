<template>
    <v-footer app dark padless>
        <v-card class="flex" flat tile>
            <v-card-title class="help">
                Besoin d'aide ?
                <v-btn
                    class="mx-2"
                    dark
                    icon
                    href="https://engie.sharepoint.com/:f:/r/sites/portailgm/Documents partages/1-Documents Utilisateurs/eDayWork?csf=1&web=1&e=Xpws1C"
                    target="_blank"
                >
                    <v-icon size="24">mdi-frequently-asked-questions</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="py-2 white--text text-center">
                <span v-if="isRGPDDisplayed" class="edaywork-RGPD-mentions">
                    <rgpd-mention />
                </span>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import rgpdMention from '../rgpd-mention';

export default {
    name: 'default-footer',

    components: { rgpdMention },
    computed: {
        isRGPDDisplayed() {
            return (
                this.$store.state.userGid &&
                (this.$store.state.userRoles.isExec ||
                    this.$store.state.userRoles.isHR ||
                    this.$store.state.userRoles.isManager)
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.edaywork-RGPD-mentions {
    font-weight: bold;
}
.help {
    padding: 5px 5px 5px 10px;
    font-size: 0.88em;
    background-color: var(--v-secondary-darken1);
}
</style>
